import React, { useEffect } from "react";
import ElementTextField from "../components/ElementTextField";
import Button from "../components/Button";
import { useState } from "react";
import "../css/addBadge.css";
import { useApi } from '../context/ApiContext';
import '../css/ElementTextField.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';





const AssignBadge = () => {
  const [badgeName, setBadgeName] = useState("");
  const [column, setColumn] = useState("");
  const [selectedFile, setSelectedFile] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  
  const { getS3Url, uploadFile } = useApi()

  const handleBadgeName = (event) => {
    setBadgeName(event.target.value);
  };

  const handleColumn = (event) => {
    setColumn(event.target.value);
  };

  const handleFile = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    if (selectedFile) {
      console.log(selectedFile.name);
    }
  }, [selectedFile]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };



  const uploadSpreadsheet = async (file) => {
    try {
        const s3Url = await getS3Url();
        console.log(s3Url)
        const result = await uploadFile(s3Url, file);
        console.log('successful')
        return('File uploaded successfully', result);
    } catch (error) {
        console.log('Error uploading file: ', error)
        return('Error uploading file: ', error);
    }
  };

  const handleSubmit = (event) => {
    // Eventually add a more strict file check before upload, such as file type.
    if(selectedFile != null && selectedFile.name.split('.')[0].toLowerCase() == "addbadges"){

      uploadSpreadsheet(selectedFile)
    }else if (selectedFile == null){
      // add alert for selecting a file to upload
      console.log("please upload a file")
      toast.error("Please upload a spreadsheet!")

      return

    }else if (selectedFile.name.toLowerCase() != "addbadges"){
      // add alert for file name
      console.log("Make sure file is named 'AddBadges' and is a spreadsheet")
      toast.error("Make sure file is named 'AddBadges' and is a spreadsheet")
      return
    }

    // handle the call to aws lambda to start the processing below

  };

  const [isChecked, setIsChecked] = useState(false); 

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); 
  };

  return (
    <div className="container">
      <div className="main-content">
        <div className="form">
          <label className="text-wrapper">
            Enter Badge and Select SpreadSheet to be assigned
          </label>
          <ElementTextField
            id="badgeName"
            label="Enter Badge Name"
            value={badgeName}
            onChange={handleBadgeName}
            placeholder=""
          />
          <input 
            className='fileInput'
            // value={ selectedFile.name || ""}
            type='File'
            id="fileInput"
            onChange={handleFile}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder=""
          />
          <ElementTextField
            id="column"
            label="Worksheet Column"
            value={column}
            onChange={handleColumn}
            placeholder=""
          />
          <label className="checkbox">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            Send Confirmation Email
          </label>
          <Button label="Submit" onClick={handleSubmit}></Button>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default AssignBadge;
