import React from 'react'
import '../css/Home.css'
import IconButtonGroup from '../components/IconButtonGroup';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/add');
  };

  const handleUnassignRedirect = () => {
    navigate('/remove');
  };

  return (
    <div className="container">
      <div className="main-content">
              <IconButtonGroup assignBadge={handleRedirect} unassignBadge={handleUnassignRedirect}></IconButtonGroup>
      </div>
    </div>
  )
}



export default Home