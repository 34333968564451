// ApiProvider.js
import React, { createContext, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import api from "./Api"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ApiContext = createContext();

export const useApi = () => {
    return useContext(ApiContext);
};

const ApiProvider = ({ children }) => {
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated()
    const [accessToken, setAccessToken] = useState(null)
    const [presignedUrl, setPresignedUrl] = useState(null);


    // Get Access Token for rest of api calls, use api.method('/path')
    const getAccessToken = async (e) => {
        if (isAuthenticated) {
            const formData = {
                "url": `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_AD_TENANT_ID}/oauth2/v2.0/token`,
                "body": {
                    "grant_type": "client_credentials",
                    "client_id": process.env.REACT_APP_AZURE_AD_CLIENT_ID,
                    "client_secret": process.env.REACT_APP_AZURE_AD_CLIENT_SECRET,
                    "scope": process.env.REACT_APP_AZURE_AD_SCOPE
                }
            }
            try {
                const response = await api.post(`/token`, formData);
                const accessToken = response.data["message"]["access_token"];
                console.log(response.data["message"]["access_token"])
                if(accessToken != null){
                    console.log("success")
                    return accessToken
                }
                else if(response.status.toString().startsWith('2')){
                    console.log("Call succeeded but there was no accesstoken")
                    toast.error("Get access token api call succeeded but didn't return an accesstoken")
                }
                else if (!response.status.toString().startsWith('2')){
                    toast.error("Get access token call failed with code: " + response.status.toString())
                }
            } catch (error) {
                toast.error("Get access token failed with error: " + String(error))
                console.error("Error getting access token", error)
            }
        }
    }
    // put the rest of the calls here and export them by adding

    const getS3Url = async () => {
        try {
            const accessToken = await getAccessToken(); // Wait for the access token to be resolved
            const response = await api.put('/s3-url', {},{
                headers: {'Authorization': `Bearer ${accessToken}`}  
            });
            console.log(response.data)
            if(response.data.presignedUrl){
                console.log(response.data.fileName)
                return response.data.presignedUrl;
            }
            else{
                console.log("No Url returned")
            }
        } catch (error) {
            toast.error("S3 Upload Url Failed with error: " + String(error))
            console.error('Error fetching data:', error);
        }
    };

    const uploadFile = async (url, file) => {
        try {
            await api.put(url, file, {
              headers: {
                'Content-Type': file.type,
              },
            });
      
            toast.success("File Uploaded Successfully")
            
          } catch (error) {
            console.error('Error uploading file:', error);
            toast.error('Error uploading file: ' + String(error))
          }
    }


    // Use Effects for getting access token if user authenticates or is authenticated.

    useEffect(() => {
        if (isAuthenticated == true) {
            getAccessToken();
        }
    }, [isAuthenticated]);

    // put the api methods you create here
    const methods = {
        getAccessToken,
        getS3Url,
        uploadFile
    }
    return <ApiContext.Provider value={methods}>{children}</ApiContext.Provider>;
};

ApiProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ApiProvider;
