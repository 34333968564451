import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import './index.css';
import App from './App';
import { msalConfig } from './AuthConfig';
import { MsalProvider } from "@azure/msal-react";
import ApiProvider from './context/ApiContext';


const msalInstance = new PublicClientApplication(msalConfig);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <ApiProvider>
          <App />
        </ApiProvider>
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>,
);
